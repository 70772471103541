import * as React from "react"
import { withPreviewResolver } from "gatsby-source-prismic"
import Layout from "../components/layout/layout"

// @ts-ignore
import linkResolver from "../utils/linkResolver.ts"
import { SectionTitle } from "../components/atoms"
import styled from "styled-components"

const PreviewPage = ({ isPreview }) => {
  if (isPreview === false) return "Not a preview!"
  return (
    <Layout>
      <Container>
        <SectionTitle>Loading Prismic Preview...</SectionTitle>
      </Container>
    </Layout>
  )
}
const Container = styled.div`
  height: 80vh;
  display: grid;
  place-items: center;
`
// @ts-ignore
export default withPreviewResolver(PreviewPage, {
  repositoryName: process.env.GATSBY_PRISMIC_REPOSITORY_NAME,
  linkResolver: () => linkResolver,
})
