// -- The Link Resolver
// This function will be used to generate links to Prismic documents
// As your project grows, you should update this function according to your routes

// @ts-ignore
const linkResolver = doc => {
  if (doc.type === "activities_page") {
    return `/activities`
  }
  if (doc.type === "activity") {
    return `/activities`
  }
  if (doc.type === "amenities_page") {
    return `/amenities`
  }
  if (doc.type === "article") {
    return `/articles/${doc.uid}`
  }
  if (doc.type === "awards_page") {
    return `/awards`
  }
  if (doc.type === "award") {
    return `/awards`
  }
  if (doc.type === "careers_page") {
    return `/careers`
  }
  if (doc.type === "concierge_page") {
    return `/concierge`
  }
  if (doc.type === "contact_page") {
    return `/contact`
  }
  if (doc.type === "faq_page") {
    return `/faq`
  }
  if (doc.type === "faq_group") {
    return `/faq`
  }
  if (doc.type === "footer") {
    return `/`
  }
  if (doc.type === "gallery_page") {
    return `/gallery`
  }
  if (doc.type === "home_page") {
    return `/`
  }
  if (doc.type === "location_page") {
    return `/location`
  }
  if (doc.type === "navigation") {
    return `/`
  }
  if (doc.type === "news_page") {
    return `/news`
  }
  if (doc.type === "ownership_page") {
    return `/ownership`
  }
  if (doc.type === "pool_page") {
    return `/pool`
  }
  if (doc.type === "popup_message") {
    return `/404`
  }
  if (doc.type === "privacy_policy") {
    return `/privacy-policy`
  }
  // this page was renamed from resort to rooms-layout
  if (doc.type === "resort_page") {
    return `/rooms-layout`
  }
  if (doc.type === "restaurants_page") {
    return `/restaurants`
  }
  if (doc.type === "restaurant") {
    return `/restaurants`
  }
  if (doc.type === "rooms_page") {
    return `/rooms`
  }
  if (doc.type === "room") {
    return `/rooms/${doc.uid}`
  }
  if (doc.type === "shops_page") {
    return `/shops`
  }
  if (doc.type === "shop") {
    return `/shops`
  }
  if (doc.type === "vacancy") {
    return `/careers/${doc.uid}`
  }
  if (doc.type === "wellness_page") {
    return `/wellness`
  }
  if (doc.type === "content_template") {
    return `/${doc.uid}`
  }
  if (doc.type === "about_us_page") {
    return `/about`
  }
  if (doc.type === "empty_page") {
    return `/${doc.uid}`
  }
  if (doc.type === "resort_map_page") {
    return `/resort-map`
  }
  return "/"
}
module.exports = linkResolver
